/*****************************************************************************************
 * 설명 : 정액상품권
 * URI : /giftcard
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button, Dialog, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { baseURL } from 'config/config';
import { showDialog } from 'reducers/dialogReducer';

import { Restful } from 'service/restful';
import { comma, PaperComponent } from 'service/utils';

import { InputEx } from 'components/inputEx';

import LayoutSub from 'pages/homepage/layout/layoutSub';
import SafeModal from './safeModal';

import KAKAO_PAY_BTN from 'assets/images/payment_icon_yellow_small.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Giftcard = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const user = useSelector(state => state?.userInfo?.user);
  const orderNumber = `order_${new Date().getTime()}`;

  const [openModal, setOpenModal] = useState({open: false, modal: 'safe', data: []});
  const [list, setList] = useState({});

  const formik = useFormik({
    initialValues: {
      giftcard: '',
      payMethod: 'card'
    },
    onSubmit: (values) => {
      // 상품명
      let tmp = list.filter((item, index) => item.seq === formik.values.giftcard);
      if( tmp.length < 1) {
        dispatch(showDialog({header: '안내', message: '구매할 정액상품권을 선택하시기 바랍니다.'}));
        return;
      }
/*
      // setSelectedGiftcard(event.target.value);
      const loadGtagScript = () => {
        // gtag.js 스크립트를 동적으로 로드
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=AW-862730987`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
          // 스크립트 로드 후 dataLayer와 gtag 함수 초기화
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-862730987');

          // Google Analytics 전환 이벤트 전송
          gtag('event', 'conversion', {
            'send_to': 'AW-862730987/sLrXCJXUu_UYEOv1sJsD',
            'value': tmp[0]?.price ?? 0,
            'currency': 'KRW',
            'transaction_id': orderNumber ?? ''
          });
        };

        script.onerror = () => {
          console.error('Google Analytics 스크립트를 로드하는 데 실패했습니다.');
        };
      };

      loadGtagScript();
*/
      handlePayment();
    }
  });

  /***************************************************************************************
   * 설명 : 서비스 정보 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {
      program: 'home',
      service: 'payment',
      action: 'getSubscriptionPlan',
      version: '1.0'
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

        formik.setFieldValue('giftcard', response.data.data[0].seq);

      } else {
        setList([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 결제 취소 함수
  ***************************************************************************************/
  const cancelPayment = (pg_code) => {
    try {
      let params = {
        program: 'home',
        service: 'payment',
        action: 'cancelPayment',
        version: '1.0',
        impUid: pg_code,
      }

      getApi("get", params).then( (response) => {
        if( response !== undefined && response.data.result ) {
          dispatch(showDialog({header: '에러', message: '결제에 실패하여 결제가 취소되었습니다.'}));

        } else {
          dispatch(showDialog({header: '에러', message: '결제를 취소하는데 실패하였습니다. 고객센터로 문의해 주세요.'}));
        }
      });

    } catch (error) {
      dispatch(showDialog({header: '에러', message: '결제를 취소하는데 실패하였습니다. 고객센터로 문의해 주세요.'}));
      console.error('Failed to cancel payment:', error);
    }
  };

  /***************************************************************************************
   * 설명 : 결제 정보 저장
  ***************************************************************************************/
  const setPaymentData = async (data, orderNumber) => {
    let params = {
      program: 'home',
      service: 'payment',
      action: 'setPaymentData',
      version: '1.0',
      memNo: user?.memNo,
      serviceName: 'subscriptionPlan',
      seq: formik.values.giftcard,
      ...data
    }

    params.price = data?.paid_amount;

    try {
      getApi("post", params).then( response => {
        if( response !== undefined && response.data.result ) {
          // 결과 페이지로 이동 - 어디? 정액상품권 사용내역으로 이동 처리
          /*
          setTimeout(() => {
            navigate('/mypage/payment');
          }, 1000);
          */
          dispatch(showDialog({header: '안내', message: response?.data?.message, click: () => navigate('/service/payment/google?serviceSeq=0&redirectUrl=1&orderNumber=' + orderNumber)}));
          // dispatch(showDialog({header: '안내', message: response?.data?.message, click: () => navigate('/mypage/payment')}));

        } else {
          // 결제가 실패했으므로 결제 취소 처리
          cancelPayment(data.imp_uid);
        }
      })
    } catch (error) {
      // 결제가 실패했으므로 결제 취소 처리
      cancelPayment(data.imp_uid);
    }
  }

  /***************************************************************************************
   * 설명 : 결제 처리 함수
  ***************************************************************************************/
  const handlePayment = () => {
    const selectedPlan = list.find(item => parseInt(item.seq) === parseInt(formik.values.giftcard));
    if (!selectedPlan) {
      dispatch(showDialog({header: '안내', message: '구매할 정액상품권을 선택하시기 바랍니다.'}));
      return;
    }

    if( formik.values.payMethod === '' ) {
      dispatch(showDialog({header: '안내', message: '결제 방식을 선택하시기 바랍니다.'}));
      return;
    }

    if( formik.values.isAgree !== true ) {
      dispatch(showDialog({header: '안내', message: '개인정보 처리방침에 동의해 주시기 바랍니다.'}));
      return;
    }

    const { IMP } = window;  // 아임포트 객체 가져오기
    IMP.init('imp75070951'); // 아임포트 관리자 콘솔에서 확인한 가맹점 식별코드 입력
    // 신규 Mqu7v9ow9Zqhs46e17J8Ldc3wQG4AwXgIU7A6nPlNncGvP87wgodlq1Voa2LS3gRJxrt0C5iAIy1e4BP
    // 기존 8wjOPMX1zHc4SUBeYe7ba5OTDSEFObQXH9LxVJyvrLAjGbyEqGQBMEvpw95llVi354A1N9weV7yBj7gK
    // 상점 아이디 : store-9fbf1c67-d188-4873-b82e-1347b653d910
    // PG상점 아이디 : G010034846

    const data = {
      pg: 'html5_inicis',
      pay_method: formik.values.payMethod,
      merchant_uid: orderNumber,  // 주문번호
      name: `정액상품권 ${selectedPlan.price}원권`,
      amount: selectedPlan.discountPrice,
      buyer_email: user?.email,
      buyer_name: user?.userName,
      buyer_tel: '',
      buyer_addr: '',
      buyer_postcode: ''
    };

    switch(formik.values.payMethod) {
      case 'phone':
        data.pg = 'danal';
        data.pay_method = 'phone';
        data.phone = user?.phone; // 휴대폰 결제용 파라미터
        break;

      case 'trans':
        data.pg = 'danal_tpay';
        data.pay_method = 'trans';
        data.buyer_tel = user?.phone; // 실시간 계좌이체용 파라미터
        break;

      case  'kakaopay':
        data.pg = 'kakaopay.CAPJLGRXWE';
        data.pay_method = 'kakaopay';

        let tmp1 = {
          memNo: user?.memNo,
          serviceName: 'subscriptionPlan',
          seq: formik.values.giftcard,
          price: selectedPlan.price,
          totalAmt: selectedPlan.discountPrice ?? selectedPlan.price,
          redirectUrl: 1,
          payMethod: 'kakaopay'
        }

        let uri = "?" + new URLSearchParams(tmp1).toString();

        data.m_redirect_url = baseURL + '/payment/kakao' + uri;
        break;

      case 'naverpay':
        // 상품명
        let tmp = list.filter((item, index) => item.seq === formik.values.giftcard);
        if( tmp.length < 1) {
          dispatch(showDialog({header: '안내', message: '구매할 정액상품권을 선택하시기 바랍니다.'}));
          return;
        }

        data.pg = 'naverpay.np_xeuwp647201';
        data.pay_method = 'card';
        data.naverUseCfm = true; // 네이버페이 사용을 명시
        data.naverChainId = 'czBYbmJyRE51eml';
        data.naverPopupMode = true;
        data.naverProducts = [
          {
            categoryType: 'ETC',
            categoryId: formik.values.giftcard,
            uid: formik.values.giftcard,
            name: comma(tmp[0]?.price) + '원권',
            count: 1,
            startDate: moment().format('YYYYMMDD'),
            endDate: moment().add(1, 'year').format('YYYYMMDD'),
          }
        ]
        break;

      case 'paypal':
        data.pg = 'paypal';
        data.pay_method = 'paypal'; // PayPal 결제 방식
        break;

      default:
        data.pg = 'html5_inicis'; // 기본 PG사 설정
        break;
    }

    IMP.request_pay(data, async (response) => {
      if (response.success) {

        // 로그인 풀림 시 결제 취소 처리
        if( ( user?.memNo ?? '' ) === '' ) {
          let params = {
            program: 'home',
            service: 'payment',
            action: 'cancelPayment',
            version: '1.0',
            impUid: response?.imp_uid,
          }

          getApi("get", params).then( (response) => {
            if( response !== undefined && response.data.result ) {
              // 결제 취소
              dispatch(showDialog({header: '에러', message: '로그인 상태가 아니어서 결제를 취소했습니다. 결제가 취소되지 않은 경우 고객센터로 문의해 주시기 바랍니다.', click: () => navigate('/login')}));

            } else {
              // 결제 취소
              dispatch(showDialog({header: '에러', message: '로그인 상태가 아니어서 결제를 취소했으나 오류가 발생하였습니다. 고객센터로 문의해서 환불 처리를 진행해 주시기 바랍니다.'}));
            }
          });

        // 결제 정보 저장 처리
        } else {
          await setPaymentData(response, orderNumber);
        }

      } else {
        dispatch(showDialog({header: '안내', message: `결제에 실패하였습니다. 에러 내용: ${response.error_msg}`}));
      }
    });
  }

  /***************************************************************************************
   * 설명 : 로그인 체크
  ***************************************************************************************/
  useEffect(() => {
    if( list.length > 0 ) {
      if( (user?.memNo ?? '') === '' ) {
        dispatch(showDialog({header: '안내', message: '결제를 진행하기 위해서는 로그인이 필요합니다.', click: () => navigate('/login')}));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  /***************************************************************************************
   * 설명 : 데이터 로딩
  ***************************************************************************************/
  useEffect(() => {
    getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <LayoutSub>
      <section className={"services-page"}>
        <form onSubmit={formik.handleSubmit}>
          <section className="service-introduce">
            <h3 className="service-title"><span>정액상품권</span></h3>
            <h3 className="service-title mobile-only"><span>정액상품권</span></h3>

            <div className="info-txt">
              <p><span className="icon1"><ArrowRightIcon/></span><span>정액상품권을 구매하시면 최대 15%의 할인혜택을 누리실 수 있습니다.</span></p>
              <p><span className="icon1"><ArrowRightIcon/></span><span>여러 건의 서비스를 이용하실 때 번거로운 결제과정 없이 편리하게 서비스를 이용하실 수 있습니다.</span></p>
            </div>
          </section>

          <div className="line"></div>

          <section>
            <div className="f16">정액상품권 보유액: <span className="cblue">{comma(user?.subscriptionRemainderAmt ?? 0)}원</span></div>
          </section>

          <div className="line"></div>

          <section className="radio-select">
            <div className="label">정액상품권 선택</div>

            <FormControl className="radio">
              <RadioGroup
                defaultValue={formik.values.giftcard}
                name="giftcard"
                color="success"
                className="radio"
                onChange={formik.handleChange}
              >
                {
                  list.length > 0 &&
                  list.map((item, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        value={item.seq}
                        control={<Radio checked={parseInt(item.seq) === parseInt(formik.values.giftcard)} />}
                        label={ comma(item.price) + "원권 : ₩" + comma(item.discountPrice) +" (" + item.discountRate + "% 할인가)"}
                      />
                    )
                  })
                }
              </RadioGroup>
            </FormControl>
          </section>

          <div className="line"></div>

          <section className="radio-select">
            <div className="label">결제수단 선택</div>
            <FormControl className="radio">
              <RadioGroup
                defaultValue={formik.values.payMethod}
                name="payMethod"
                color="success"
                className="radio"
                onChange={formik.handleChange}
              >
                <FormControlLabel value={'card'} control={<Radio />} label="신용카드" />
                {/*
                  user?.userId === 'sys1000' &&
                  <>
                    <FormControlLabel value={'naverpay'} control={<Radio />}
                      label={
                        <div className="flex-row flex-center">
                          <img src={NAVER_BTN} alt="네이버페이" style={{height: '25px'}} />
                          <div className="ml10">네이버페이</div>
                        </div>
                      }
                    />
                    <FormControlLabel value={'paypal'} control={<Radio />} label="페이팔" />
                  </>
                */}
                <FormControlLabel value={'kakaopay'} control={<Radio />}
                  label={
                    <div className="flex-row flex-center">
                      <img src={KAKAO_PAY_BTN} alt="카카오페이" style={{height: '25px'}} />
                      <div className="ml10">카카오페이</div>
                    </div>
                  }
                />
                <FormControlLabel value={'phone'} control={<Radio />} label="휴대폰 소액결제" />
                <FormControlLabel value={'trans'} control={<Radio />} label="실시간 계좌이체" />
              </RadioGroup>
            </FormControl>
          </section>

          <NavLink
            className="link-txt-green mt30"
            onClick={() => {
              setOpenModal({open: true, modal: 'safe', data: formik.values});
            }}
          >사주인의 결제가 안전한 이유 &gt;&gt;</NavLink>

          <div className="line"></div>

          <section >
            <div className="agree-div">
              <label className="check">
                <InputEx
                  type="checkbox"
                  name="isAgree"
                  formik={formik}
                  className="check1"
                />
                개인정보 처리방침에 동의함
                <san className="cred">(필수)</san>
              </label>
            </div>

            <NavLink to="/contents?seq=98" target="_blank" className="link-txt-green mt15 mb20">개인정보 처리방침 보기 &gt;&gt;</NavLink>
            {/*
            <div className="info-box">
              <h5>유의사항</h5>
              <p>결제완료 후에는 ‘새로고침' 또는 ‘뒤로' 버튼을 누르지 마세요. 본인의 실수로 인한 경우 환불되지 않습니다.</p>
            </div>
            */}
          </section>

          <section className="center mt40">
            <Button
              type="submit"
              variant="contained"
              className="hbasic-btn green"
            >결제하기</Button>
          </section>
        </form>
      </section>

      { openModal.open && openModal.modal === 'safe' &&
        <Dialog
          open={openModal.open}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({ open: false, modal: openModal.modal, data: openModal.data });
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
                borderRadius: "12px"
              },
            },
          }}
        >
          <section className="home-modal">
            <SafeModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modal: openModal.modal, data: openModal.data })}
            />
          </section>
        </Dialog>
      }
    </LayoutSub>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Giftcard;