/*****************************************************************************************
 * 설명 : 회원가입
 * URI : /join
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button, FormControl, FormControlLabel, InputAdornment, Radio, RadioGroup } from '@mui/material';
import { CheckBoxEx, InputEx, SelectEx } from 'components/inputEx';
import { useFormik } from 'formik';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from "yup";

import { baseURL, timeList } from 'config/config';
import { showDialog } from 'reducers/dialogReducer';
import { setUserInfo } from 'reducers/userReducer';

import { Restful } from 'service/restful';

import Layout from 'pages/homepage/layout/layout';

import '../member.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Join = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const navigate = useNavigate();
  const [getApi] = Restful();
  const dispatch = useDispatch()

  const [yearList, setYearList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [dayList, setDayList] = useState([]);

  const formik = useFormik({
    initialValues: {
      user_type_cd: 'sj',
      password: '',
      name: '',
      email: '',
      yyyymmdd: '',
      email_yn: 'y',
      sjms_year: '1980',
      sjms_month: '',
      sjms_day: '',
      sjms_sex: '',
      sjms_hour: '',
      sjms_solunar: 'solar',
      agree: '',
      isCheck: false
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(30, '30자리').required('필수'),
      email: Yup.string().max(100, "100자리").required('필수')
      .test(
        'email check',
        '이메일 형식 체크',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== 'function' ) return true;

          return tmp.match( /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
        }
      ),
      /*
      password: Yup.string().min(1, '최소1자').max(30, "30자리").required('필수')
      .test(
        'password change check',
        '영문대/소문자,숫자,특수문자 미포함',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== "function" ) return true;
          if( tmp === '' || tmp === 'undefined' ) return true;

          return tmp.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&()\-_+=~./])[A-Za-z\d$@$!%*#?&()-_+=~./]{8,16}$/) ? true : false;
        }
      )
      .test(
        'password change check',
        '영문대/소문자,숫자,특수문자',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== "function" ) return true;

          return tmp.match(/^[A-Za-z0-9$^@$!%*#?&()\-_+=~./]+$/);
        }
      ),
      */
      password: Yup.string().min(8, '최소 8자').max(30, "30자리").required('필수')
      .test(
        'password change check',
        '영문 알파벳과 숫자 포함 8자 이상',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== "function" ) return true;
          if( tmp === '' || tmp === 'undefined' ) return true;

          // 영문 알파벳과 숫자가 모두 포함되어 있으며 8자 이상인지 확인
          return tmp.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/) ? true : false;
        }
      ),
      passwordConfirm: Yup.string().min(1, '최소1자').max(30, "30자리").required('필수').when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "불일치"
        )
      }),
    }),
    onSubmit: (values) => {
      if( ( values.agree ?? '' ) === '' ) {
        dispatch(showDialog({header: '안내', message: '이용약관 및 개인정보처리방침에 동의해야 회원가입이 가능합니다.'}));
        return;

      } else if( values.isCheck !== true ) {
        dispatch(showDialog({header: '안내', message: '아이디 중복확인이 필요합니다.'}));
        return;
      }

      // 중복된 이메일 주소 확인
      let params = {
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'checkDuplicateEmail',
        email: formik.values.email
      }

      getApi("get", params).then( response => {
        if( response !== undefined && response.data.result ) {
          handlePopup();

        } else {
          dispatch(showDialog({header: '에러', message: response.data.message}));
        }
      });
    }
  });

  /***************************************************************************************
   * 설명 : 나이스 창 호출
  ***************************************************************************************/
  const handlePopup = (tokenVersionId, encData, integrity) => {
    const form = document.createElement('form');

    form.method = 'POST';
    form.action = baseURL + '/api/api/niceapi/checkplus_main.php';
    form.target = 'popupChk';

    document.body.appendChild(form);
    window.open('', 'popupChk', 'width=480, height=812, top=100, fullscreen=no, menubar=no, status=no, toolbar=no,titlebar=yes, location=no, scrollbar=no');

    form.submit();

    document.body.removeChild(form);
  };

  /***************************************************************************************
   * 설명 : 나이스 휴대폰 본인인증 결과 성공 시 처리
  ***************************************************************************************/
  const niceSuccessCallFunc = (data) => {
    try {
      // 회원가입 처리 - 휴대폰 인증 값
      let params = {
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setMemberJoin',
        ...formik.values,
        hpSeq: data
      }

      getApi("post", params).then( response => {
        if( response !== undefined && response.data.result ) {
          window.localStorage.setItem("sajuin_token", response.data.token );

          dispatch(
            setUserInfo({
              ...formik.values,
              id: response.data.memNo
            })
          );

          dispatch(showDialog({header: '안내', message: response.data.message, click: () => navigate('/')}));

        } else {
          dispatch(showDialog({header: '에러', message: response.data.message}));
        }
      });

    } catch(e) {
      console.error('niceSuccessCallFunc', e);
    }
  }

  // 부모 창의 window 객체에 parentFunction을 할당
  window.niceSuccessCallFunc = niceSuccessCallFunc;

  /***************************************************************************************
   * 설명 : 중복아이디 체크
  ***************************************************************************************/
  const duplicateId = () => {
    if( (formik.values.email ?? '') === '' ) {
      dispatch(showDialog({header: '안내', message: '중복검사할 이메일 주소를 입력하시기 바랍니다.'}));
      return;
    }

    let params = {
      program: 'home',
      service: 'member',
      version: '1.0',
      action: 'checkDuplicateId',
      email: formik.values.email
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result ) {
        dispatch(showDialog({header: '안내', message: response.data.message}));
        formik.setFieldValue('isCheck', true);

      } else {
        dispatch(showDialog({header: '에러', message: response.data.message}));
        formik.setFieldValue('isCheck', false);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    // 년도 계산
    let tmp = [];
    for(let i = 1900; i <= moment().format('YYYY'); i++ ) {
      tmp.push({label: i + '년', value: i});
    }
    setYearList(tmp);

    formik.setFieldValue('sjms_year', moment().add(-24, 'year').format('YYYY'));

    // 월 계산
    tmp = [];
    for(let i = 1; i <= 12; i++ ) {
      let tmp1 = (i < 10) ? '0' + i : i;
      tmp.push({label: tmp1 + '월', value: tmp1});
    }
    setMonthList(tmp);

    // 일 계산
    tmp = [];
    for(let i = 1; i <= 31; i++ ) {
      let tmp1 = (i < 10) ? '0' + i : i;
      tmp.push({label: tmp1 + '일', value: tmp1});
    }
    setDayList(tmp);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className ="member-home">
        <h1 className="page-title">회원가입</h1>

        <form onSubmit={formik.handleSubmit}>
          <div className="login-wrap">
            <label className="label" htmlFor="email">이메일(아이디)</label>
            <div>
              <InputEx
                name="email"
                formik={formik}
                className="inputbox id"
                variant="outlined"
                placeholder="이메일"
                autoFocus
                onChange={() => {
                  formik.setFieldValue('isCheck', false);
                }}
                style={{width: 'calc(70% - 12px)'}}
              />

              <Button
                className="btn1 ml10 p0"
                onClick={duplicateId}
                style={{padding: '0px !important', width: '30%', minWidth: '30%', maxWidth:'30%'}}
              >중복확인</Button>
            </div>

            <label className="label mt20" htmlFor="name">이름</label>
            <InputEx
              name="name"
              formik={formik}
              fullWidth={true}
              className="inputbox"
              variant="outlined"
              placeholder="이름"
            />

            <label className="label mt20" htmlFor="password">비밀번호</label>
            <InputEx
              type="password"
              name="password"
              formik={formik}
              fullWidth={true}
              variant="outlined"
              className="inputbox"
              placeholder="영문 알파벳과 숫자 포함 8~15자 이내"
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />

            <label className="label mt20" htmlFor="passwordConfirm">비밀번호 확인</label>
            <InputEx
              type="password"
              name="passwordConfirm"
              formik={formik}
              fullWidth={true}
              variant="outlined"
              className="inputbox"
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />

            <label className="label mt20" htmlFor="sjms_sex">성별</label>
            <FormControl className="radio">
              <RadioGroup
                row
                defaultValue={'남'}
                name="sjms_sex"
                color="success"
                className="radio"
                onChange={formik.handleChange}
              >
                <FormControlLabel value={'남'} control={<Radio />} label="남" />
                <FormControlLabel value={'여'} control={<Radio />} label="여" />
              </RadioGroup>
            </FormControl>

            <label className="label mt20" htmlFor="year">생년월일</label>
            <div className="select-div">
              <SelectEx
                name="sjms_year"
                formik={formik}
                className="fl selectbox year"
                variant="outlined"
                data={[
                  {label: '년도선택', value: ''}
                ].concat(yearList)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">년</InputAdornment>,
                }}
                style={{width: '33%'}}
              />

              <SelectEx
                name="sjms_month"
                formik={formik}
                className="fl selectbox ml10"
                variant="outlined"
                data={[
                  {label: '월선택', value: ''}
                ].concat(monthList)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">월</InputAdornment>,
                }}
                style={{width: 'calc(33% - 10px)'}}
              />

              <SelectEx
                name="sjms_day"
                formik={formik}
                className="fl selectbox ml10"
                variant="outlined"
                data={[
                  {label: '일선택', value: ''}
                ].concat(dayList)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">일</InputAdornment>,
                }}
                style={{width: 'calc(33% - 10px)'}}
              />
            </div>

            <div className="clearfix" />

            <label className="label mt20">생시</label>
            <div className="select-div">
              <SelectEx
                name="sjms_hour"
                formik={formik}
                fullWidth={true}
                className="selectbox"
                data={[
                  {label: '생시', value: ''}
                ].concat(timeList)}
              />
            </div>

            <label className="label mt20">양/음력</label>
            <div className="select-div">
              <SelectEx
                name="sjms_solunar"
                formik={formik}
                fullWidth={true}
                className="selectbox"
                data={[
                  {label: '양력', value: 'solar'},
                  {label: '음력(평달)', value: 'lunar0'},
                  {label: '음력(윤달)', value: 'lunar1'}
                ]}
              />
            </div>

            <div className="agree-div">
              <CheckBoxEx
                name="agree"
                color="success"
                formik={formik}
                className="check"
                iconStyle={{fill: '#fff'}}
                label="이용약관, 개인정보 처리방침에 동의 합니다."
              />
            </div>

          <NavLink to="/contents?seq=97" target="_blank" className="info-txt join-info-txt">이용약관 자세히보기 &gt;&gt;</NavLink>
          <NavLink to="/contents?seq=98" target="_blank" className="info-txt join-info-txt">개인정보 처리방침 자세히보기 &gt;&gt;</NavLink>

            <Button
              type="submit"
              variant="contained"
              className="login-btn green mt30"
            >휴대폰 본인인증</Button>

          </div>
        </form>
      </section>

    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Join;